//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PreferenceService } from "../../../services/preference.service";
import { SearchService } from "../../../services/search/search.service";
import { EventCategoryService } from "../../../services/master.definitions/categories/event.category.service";
import { TelemetryService } from "../../../services/telemetry.service";

@TypeManagerDecorator("system-user-defaults")
@Component({
    selector: "system-user-defaults",
    template: `
        <h2 class="c-margin-bottom--single">User Defaults</h2>
        <div class="c-margin-bottom--single">
            <div class="ngBold c-margin-bottom--quarter">Default 25Live Home Mode</div>
            <p class="ngFinePrint c-margin-bottom--half">The default view for the 25Live Home tab.</p>
            <label for="Dashboard">
                <input
                    type="radio"
                    [(ngModel)]="this.homeMode"
                    name="homeMode"
                    class="s25-input"
                    id="dashboard"
                    value="my25live"
                    [checked]="this.homeMode === 'my25live'"
                />
                Dashboard
            </label>
            <label class="c-margin-left--single" for="Calendar">
                <input
                    type="radio"
                    [(ngModel)]="this.homeMode"
                    name="homeMode"
                    class="s25-input"
                    id="Calendar"
                    value="calendar"
                    [checked]="this.homeMode === 'calendar'"
                />
                Calendar
            </label>
            <label class="c-margin-left--single" for="HomeAvailability">
                <input
                    type="radio"
                    [(ngModel)]="this.homeMode"
                    name="homeMode"
                    class="s25-input"
                    id="HomeAvailability"
                    value="availability"
                    [checked]="this.homeMode === 'availability'"
                />
                Home Availability
            </label>
        </div>

        <div class="c-margin-bottom--single">
            <label for="userName" class="ngBold"> Default 25Live Dashboard Username </label>
            <p class="ngFinePrint c-margin-bottom--half">
                The username of the contact whose dashboard configuration will be the default for all new 25Live users.
            </p>
            <div>
                <input
                    type="text"
                    id="userName"
                    [(ngModel)]="this.userName"
                    class="c-input"
                    [value]="this.userName"
                    (change)="onChange()"
                />
            </div>
        </div>

        <div class="c-margin-bottom--single">
            <label for="homeSearch" class="ngBold"> Default 25Live Home Search </label>
            <p class="ngFinePrint c-margin-bottom--half">
                The default search to display events in the 25Live Home Calendar view.
            </p>
            <div>
                @if (publicSearchList) {
                    <select class="ngListPageDropdown" [(ngModel)]="this.homeSearch" name="homeSearch">
                        @for (r of publicSearchList; track r) {
                            <option [ngValue]="r.val">{{ r.txt }}</option>
                        }
                    </select>
                }
            </div>
        </div>

        <div class="c-margin-bottom--single">
            <div class="ngBold c-margin-bottom--quarter">Event Default View</div>
            <p class="ngFinePrint c-margin-bottom--half">The default view for an individual event.</p>
            <label for="eventList">
                <input
                    type="radio"
                    [(ngModel)]="this.eventDefaultView"
                    name="eventDefaultView"
                    class="s25-input"
                    id="eventList"
                    value="Details"
                    [checked]="this.eventDefaultView === 'Details'"
                />
                Details
            </label>
            <label class="c-margin-left--single" for="eventCalendar">
                <input
                    type="radio"
                    [(ngModel)]="this.eventDefaultView"
                    name="eventDefaultView"
                    class="s25-input"
                    id="eventCalendar"
                    value="Calendar"
                    [checked]="this.eventDefaultView === 'Calendar'"
                />
                Calendar
            </label>
        </div>

        <div class="c-margin-bottom--single">
            <div class="ngBold c-margin-bottom--quarter">Location Default View</div>
            <p class="ngFinePrint c-margin-bottom--half">The default view for an individual location.</p>
            <label for="locationDetails">
                <input
                    type="radio"
                    [(ngModel)]="this.locDefaultView"
                    name="locDefaultView"
                    class="s25-input"
                    id="locationDetails"
                    value="Details"
                    [checked]="this.locDefaultView === 'Details'"
                />
                Details
            </label>
            <label class="c-margin-left--single" for="locationList">
                <input
                    type="radio"
                    [(ngModel)]="this.locDefaultView"
                    name="locDefaultView"
                    class="s25-input"
                    id="locationList"
                    value="List"
                    [checked]="this.locDefaultView === 'List'"
                />
                List
            </label>
            <label class="c-margin-left--single" for="locationAvailability">
                <input
                    type="radio"
                    [(ngModel)]="this.locDefaultView"
                    name="locDefaultView"
                    class="s25-input"
                    id="locationAvailability"
                    value="Availability"
                    [checked]="this.locDefaultView === 'Availability'"
                />
                Availability
            </label>
            <label class="c-margin-left--single" for="locationCalendar">
                <input
                    type="radio"
                    [(ngModel)]="this.locDefaultView"
                    name="locDefaultView"
                    class="s25-input"
                    id="locationCalendar"
                    value="Calendar"
                    [checked]="this.locDefaultView === 'Calendar'"
                />
                Calendar
            </label>
        </div>

        <div class="c-margin-bottom--single">
            <div class="ngBold c-margin-bottom--quarter">Resource Default View</div>
            <p class="ngFinePrint c-margin-bottom--half">The default view for an individual Resource.</p>
            <label for="resourceDetails">
                <input
                    type="radio"
                    [(ngModel)]="this.resDefaultView"
                    name="resDefaultView"
                    class="s25-input"
                    id="resourceDetails"
                    value="Details"
                    [checked]="this.resDefaultView === 'Details'"
                />
                Details
            </label>
            <label class="c-margin-left--single" for="resourceList">
                <input
                    type="radio"
                    [(ngModel)]="this.resDefaultView"
                    name="resDefaultView"
                    class="s25-input"
                    id="resourceList"
                    value="List"
                    [checked]="this.resDefaultView === 'List'"
                />
                List
            </label>
            <label class="c-margin-left--single" for="resourceAvailability">
                <input
                    type="radio"
                    [(ngModel)]="this.resDefaultView"
                    name="resDefaultView"
                    class="s25-input"
                    id="resourceAvailability"
                    value="Availability"
                    [checked]="this.resDefaultView === 'Availability'"
                />
                Availability
            </label>
            <label class="c-margin-left--single" for="resourceCalendar">
                <input
                    type="radio"
                    [(ngModel)]="this.resDefaultView"
                    name="resDefaultView"
                    class="s25-input"
                    id="resourceCalendar"
                    value="Calendar"
                    [checked]="this.resDefaultView === 'Calendar'"
                />
                Calendar
            </label>
        </div>

        <div class="c-margin-bottom--single">
            <div class="ngBold c-margin-bottom--quarter">Organization Default View</div>
            <p class="ngFinePrint c-margin-bottom--half">The default view for an individual organization.</p>
            <label for="organizationDetails">
                <input
                    type="radio"
                    [(ngModel)]="this.orgDefaultView"
                    name="orgDefaultView"
                    class="s25-input"
                    id="organizationDetails"
                    value="Details"
                    [checked]="this.orgDefaultView === 'Details'"
                />
                Details
            </label>
            <label class="c-margin-left--single" for="organizationList">
                <input
                    type="radio"
                    [(ngModel)]="this.orgDefaultView"
                    name="orgDefaultView"
                    class="s25-input"
                    id="organizationList"
                    value="List"
                    [checked]="this.orgDefaultView === 'List'"
                />
                List
            </label>
            <label class="c-margin-left--single" for="organizationCalendar">
                <input
                    type="radio"
                    [(ngModel)]="this.orgDefaultView"
                    name="orgDefaultView"
                    class="s25-input"
                    id="organizationCalendar"
                    value="Calendar"
                    [checked]="this.orgDefaultView === 'Calendar'"
                />
                Calendar
            </label>
        </div>

        <div class="c-margin-bottom--single">
            <div class="ngBold c-margin-bottom--quarter">Availability Grid Location Display Name</div>
            <p class="ngFinePrint c-margin-bottom--half">Location name display</p>
            <div id="locationDisplay">
                <s25-toggle-button
                    id="event"
                    [(modelValue)]="this.availUseLongName"
                    [trueLabel]="'Formal Name'"
                    [falseLabel]="'Name'"
                ></s25-toggle-button>
            </div>
        </div>

        <div class="c-margin-bottom--single">
            <div class="ngBold c-margin-bottom--quarter">Availability Grid Display Capacity</div>
            <p class="ngFinePrint c-margin-bottom--half">
                Show the default capacity next to each location name in availability grids
            </p>
            <div id="locationDisplay">
                <s25-toggle-button
                    id="event"
                    [(modelValue)]="this.availDisplayCapacity"
                    [trueLabel]="'Default Layout Capacity'"
                    [falseLabel]="'No Capacity'"
                ></s25-toggle-button>
            </div>
        </div>

        <div class="c-margin-bottom--single">
            <div class="ngBold c-margin-bottom--quarter">Pricing Mode</div>
            <p class="ngFinePrint c-margin-bottom--half">Select the default pricing mode for events</p>
            <div>
                <s25-toggle-button
                    [(modelValue)]="usePaymentsMode"
                    [trueLabel]="'Payment'"
                    [falseLabel]="'Standard'"
                ></s25-toggle-button>
            </div>
        </div>

        <div class="c-margin-bottom--single">
            <div class="ngBold c-margin-bottom--quarter">Set AI Feature Access</div>
            <p class="ngFinePrint c-margin-bottom--half">Turns on AI Express Scheduling.</p>
            <s25-toggle-button
                [(modelValue)]="hasAiFeatures"
                [trueLabel]="'On'"
                [falseLabel]="'Off'"
            ></s25-toggle-button>
        </div>

        <div class="c-displayBlock c-padding-bottom--double">
            <button
                class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                (click)="save()"
                [disabled]="!this.isValidEmail"
            >
                {{ this.loading ? "Saving...." : "Save" }}
            </button>
            <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()">Cancel</button>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDefaultsComponent implements OnInit {
    @Input() falseLabel: string = "Hide reminder link";
    @Input() trueLabel: string = "Show reminder link";
    @Output() modelValueChange = new EventEmitter();
    loading: boolean = false;
    isValidEmail: boolean = true;
    homeMode: string = "";
    homeSearch: string = "";
    userName: string = "";
    eventDefaultView: string = "";
    locDefaultView: string = "";
    resDefaultView: string = "";
    orgDefaultView: string = "";
    initHomeMode: string = "";
    initHomeSearch: string = "";
    initUserName: string = "";
    initEventDefaultView: string = "";
    initLocDefaultView: string = "";
    initResDefaultView: string = "";
    initOrgDefaultView: string = "";
    publicSearchList: any = [];
    hotEventName: string = "Hot Event";
    initAvailUseLongName: boolean = false;
    availUseLongName: boolean = this.initAvailUseLongName;
    initAvailDisplayCapacity: boolean = true;
    availDisplayCapacity: boolean = this.initAvailDisplayCapacity;
    initPricingMode: boolean = false;
    usePaymentsMode: boolean = this.initPricingMode;
    initAiFeatures: boolean = true;
    hasAiFeatures: boolean = this.initAiFeatures;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    onChange() {
        this.cd.detectChanges();
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.getPrefs();
        this.getSearch();
        this.getEventCat();
        this.cd.detectChanges();
    }

    getPrefs() {
        return S25Util.all({
            prefs: PreferenceService.getPreferences(
                [
                    "25L_home_mode",
                    "config_dashboard_user",
                    "25L_my25live_happening_source",
                    "25L_org_details_view",
                    "25L_event_details_view",
                    "25L_resource_details_view",
                    "25L_space_details_view",
                    "availCapacity",
                    "availSpaceName",
                    "pricingMode",
                    "HasAiFeatures",
                ],
                "S",
            ),
        }).then((resp) => {
            this.initUserName = this.userName = resp.prefs.config_dashboard_user.value;
            this.initHomeMode = this.homeMode = resp.prefs["25L_home_mode"].value.mode;
            this.initHomeSearch = this.homeSearch = resp.prefs["25L_my25live_happening_source"].value.source;
            this.initEventDefaultView = this.eventDefaultView = resp.prefs["25L_event_details_view"].value.view;
            this.initLocDefaultView = this.locDefaultView = resp.prefs["25L_space_details_view"].value.view;
            this.initResDefaultView = this.resDefaultView = resp.prefs["25L_resource_details_view"].value.view;
            this.initOrgDefaultView = this.orgDefaultView = resp.prefs["25L_org_details_view"].value.view;
            this.initAvailUseLongName = this.availUseLongName = resp.prefs["availSpaceName"]?.value === "room_long";
            this.initAvailDisplayCapacity = this.availDisplayCapacity =
                resp.prefs["availCapacity"]?.value === "defaultLayout";
            this.initPricingMode = this.usePaymentsMode = resp.prefs["pricingMode"]?.value === "payment";
            this.initAiFeatures = this.hasAiFeatures = resp.prefs["HasAiFeatures"]?.value === "T";
            this.cd.detectChanges();
        });
    }

    getEventCat() {
        EventCategoryService.getSingleton()
            .get("?category_id=-2")
            .then((resp: any) => {
                if (resp) {
                    this.hotEventName = resp[0].category_name;
                }
            });
    }

    getSearch() {
        return S25Util.all({
            eventPublicSearch: SearchService._getSearches(1),
            locPublicSearch: SearchService._getSearches(4),
        }).then((resp) => {
            let eventPublicSearchList = resp.eventPublicSearch.opt.filter((data: any) => {
                return data.grp === "Public Event Searches";
            });

            let locPublicSearchList = resp.locPublicSearch.opt.filter((data: any) => {
                return data.grp === "Public Searches";
            });

            this.publicSearchList = eventPublicSearchList.concat(locPublicSearchList);

            this.publicSearchList = this.publicSearchList.map((p: any) =>
                p.grp === "Public Event Searches"
                    ? { ...p, val: "reservations.xml?event_" + p.val.replace("&", "") }
                    : { ...p, txt: "Event in " + p.txt, val: "reservations.xml?space_" + p.val.replace("&", "") },
            );

            let hotEvent = [{ txt: this.hotEventName, val: "reservations.xml?hot_events=T" }];
            this.publicSearchList = hotEvent.concat(this.publicSearchList);

            this.cd.detectChanges();
        });
    }

    async save() {
        this.loading = true;
        this.cd.detectChanges();
        let homeMode = "<mode>" + this.homeMode + "</mode>";
        let homeSearch = "<source>" + this.homeSearch + "</source>";
        let eventDefaultView = "<view>" + this.eventDefaultView + "</view>";
        let locDefaultView = "<view>" + this.locDefaultView + "</view>";
        let resDefaultView = "<view>" + this.resDefaultView + "</view>";
        let orgDefaultView = "<view>" + this.orgDefaultView + "</view>";

        this.initPricingMode !== this.usePaymentsMode &&
            TelemetryService.sendWithSub("SysSettings", "UserDefaults", "PaymentMode");

        let promiseArr = [];
        this.initUserName !== this.userName &&
            promiseArr.push(PreferenceService.setPreference("config_dashboard_user", this.userName, "S"));
        this.initHomeMode !== this.homeMode &&
            promiseArr.push(PreferenceService.setPreference("25L_home_mode", homeMode, "S"));
        this.initHomeSearch !== this.homeSearch &&
            promiseArr.push(PreferenceService.setPreference("25L_my25live_happening_source", homeSearch, "S"));
        this.initEventDefaultView !== this.eventDefaultView &&
            promiseArr.push(PreferenceService.setPreference("25L_event_details_view", eventDefaultView, "S"));
        this.initLocDefaultView !== this.locDefaultView &&
            promiseArr.push(PreferenceService.setPreference("25L_space_details_view", locDefaultView, "S"));
        this.initResDefaultView !== this.resDefaultView &&
            promiseArr.push(PreferenceService.setPreference("25L_resource_details_view", resDefaultView, "S"));
        this.initOrgDefaultView !== this.orgDefaultView &&
            promiseArr.push(PreferenceService.setPreference("25L_org_details_view", orgDefaultView, "S"));
        this.initAvailUseLongName !== this.availUseLongName &&
            promiseArr.push(
                PreferenceService.setPreference(
                    "availSpaceName",
                    this.availUseLongName ? "room_long" : "room_short",
                    "S",
                ),
            );
        this.initAvailDisplayCapacity !== this.availDisplayCapacity &&
            promiseArr.push(
                PreferenceService.setPreference(
                    "availCapacity",
                    this.availDisplayCapacity ? "defaultLayout" : "none",
                    "S",
                ),
            );
        this.initPricingMode !== this.usePaymentsMode &&
            promiseArr.push(
                PreferenceService.setPreference("pricingMode", this.usePaymentsMode ? "payment" : "standard", "S"),
            );
        this.initAiFeatures !== this.hasAiFeatures &&
            promiseArr.push(PreferenceService.setPreference("HasAiFeatures", this.hasAiFeatures ? "T" : "F", "S"));

        if (promiseArr) {
            return S25Util.all(promiseArr).then((resp) => {
                this.loading = false;
                this.cd.detectChanges();
            });
        }
    }

    cancel() {
        this.getPrefs();
        this.cd.detectChanges();
    }
}
